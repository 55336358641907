import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./sendRequest.css";
import axios from "axios";
import DatePicker from "../../../components/datepicker";

const SendRequest = () => {

    // Função para obter a data de hoje no formato "YYYY-MM-DD"
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        return `${year}-${month}-${day}`;
    };

    const validationSchema = yup.object({
        client: yup.string().required("Esse campo é obrigatório!"),
        service: yup.string().required("Esse campo é obrigatório!"),
        numOrder: yup.string().required("Esse campo é obrigatório!"),
        data: yup.string().required("Esse campo é obrigatório!") // Adiciona a validação para a data
    });

    const handleSend = (values) => {
        console.log(values);
        axios.post("https://octview.imaginecode.shop/services", values)
            .then()
            .catch(error => {
                console.error('houve um erro no cadastro:', error);
            });

        document.getElementById("limpar").value = "";
        document.getElementById("limpar1").value = "";
        document.getElementById("limpar2").value = "";
        alert("Pedido Criado Com sucesso");
    };

    return (
        <main className="main-request">
            <Formik
                initialValues={{
                    numOrder: "",
                    client: "",
                    service: "",
                    data: getTodayDate(), // Define a data inicial como o dia de hoje
                    urgent: false // Valor padrão para o checkbox
                }}
                validationSchema={validationSchema}
                onSubmit={handleSend}
            >
                <div className="form-serviceOut">
                    <Form className="form-service">
                        <h1>Envie a Ordem de Serviço</h1>
                        <Field
                            name="numOrder"
                            placeholder="Digite o Nº da Ordem"
                            id="limpar2"
                        />
                        <ErrorMessage component={"span"} name="numOrder" />
                        <Field
                            name="client"
                            placeholder="Digite o Cliente"
                            id="limpar"
                        />
                        <ErrorMessage component={"span"} name="client" />

                        <Field
                            as="textarea"
                            cols="60"
                            name="service"
                            placeholder="Digite o serviço"
                            id="limpar1"
                            wrap="hard"
                        />
                        <ErrorMessage component={"span"} name="service" />

                        {/* Campo de data com valor inicial como o dia de hoje */}
                        <Field name="data" component={DatePicker} />
                        <ErrorMessage component={"span"} name="data" />

                        <div className="sendUrgent">
                            <label htmlFor="urgent">
                                Urgência?
                            </label>
                            <Field
                                name="urgent"
                                type="checkbox"
                            />
                        </div>

                        <button className="btn" type="submit">Enviar</button>
                    </Form>
                </div>
            </Formik>
        </main>
    );
};

export default SendRequest;
