import React, { useEffect, useState } from "react";
import axios from "axios";
import './watchService.css';

const WatchService = () => {
    const [newOrders, setNewOrders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState({});

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axios.get("https://octview.imaginecode.shop/findServices");
                setNewOrders(response.data);
            } catch (error) {
                console.error("Houve um erro", error);
            }
        };

        // Intervalo de polling para buscar novos pedidos a cada 5 segundos
        const interval = setInterval(fetchOrder, 100);
        
        // Buscar imediatamente ao carregar a página
        fetchOrder();

        // Limpar o intervalo ao desmontar o componente
        return () => clearInterval(interval);
    }, []);

    
    const handleSelectChange = (orderId, event) => {
        setSelectedProvider((prevSelectedProvider) => ({
            ...prevSelectedProvider,
            [orderId]: event.target.value,
        }));
    };

    const handleAccept = async (order) => {
        const provider = selectedProvider[order.id];
        console.log(provider);

        if (!provider || provider === "Quem Aceitou?") {
            alert("Por favor, selecione um provedor antes de aceitar.");
            return;
        }

        try {
            await axios.post("https://octview.imaginecode.shop/updateServices", {
                id: order.id,
                provider: provider,
                status: "Em Andamento"
            }).then(() => console.log("Pedido atualizado!"));
        } catch (error) {
            console.error("Erro ao atualizar pedido", error);
        }
    };

    const getDateStatus = (orderDate) => {
        const now = new Date();
        const noww = now.toISOString().split('T')[0];
        const orderDateObj = new Date(orderDate);
        const orderDateObjc = orderDateObj.toISOString().split('T')[0];
        const tomorrow = new Date(noww);
        tomorrow.setDate(now.getDate());
        const tomorroww = tomorrow.toISOString().split('T')[0];

        if (orderDateObjc < noww) {
            return "Atrasado";
        } else if (orderDateObjc === noww) {
            return "Hoje";
        } else if (orderDateObjc === tomorroww) {
            return "Amanhã";
        } else {
            return "Agendado";
        }
    };

    const categorizedOrders = {
        Atrasado: [],
        Hoje: [],
        Amanhã: [],
        Agendado: []
    };

    newOrders.forEach(order => {
        const status = getDateStatus(order.data);
        categorizedOrders[status].push(order);
    });

    categorizedOrders.Hoje.sort((a, b) => b.urgent - a.urgent);

    return (
        <main className="main-OctView">
            <div className="titleApp">
                <div className="confTitle">
                    <h1 className="teste">OCTView</h1>
                </div>
            </div>
            <main className="main-watch">
                <div className="services-listAtrasado">
                    <div className="titleCardAtrasado">
                        <h1>Atrasado</h1>
                    </div>
                    {categorizedOrders.Atrasado.length > 0 ? (
                        categorizedOrders.Atrasado.map((order, index) => (
                            <div key={index} className="service-itemA">
                                <h2>Ordem: {order.numOrder}</h2>
                                <h2>Cliente: {order.client}</h2>
                                <p>Defeito: {order.service}</p>
                                <div className="btndivWatch">
                                    <button className="btnWatch" onClick={() => handleAccept(order)}>Aceitar</button>
                                    <select
                                        name="select"
                                        id="select"
                                        onChange={(event) => handleSelectChange(order.id, event)}
                                        required
                                    >
                                        <option>Quem Aceitou?</option>
                                        <option value="André">André</option>
                                        <option value="Leandro">Leandro</option>
                                        <option value="Cristian">Cristian</option>
                                        <option value="Erik">Erik</option>
                                    </select>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhum chamado disponível.</p>
                    )}
                </div>

                <div className="services-listHoje">
                    <div className="titleCardHoje">
                        <h1>Hoje</h1>
                    </div>
                    {categorizedOrders.Hoje.length > 0 ? (
                        categorizedOrders.Hoje.map((order, index) => (
                            <div key={index} className="service-itemH">
                                {order.urgent && (
                                    <div className="alertUrgent">
                                        <h6>URGÊNCIA</h6>
                                    </div>
                                )}  
                                <h2>Ordem: {order.numOrder}</h2>
                                <h2>Cliente: {order.client}</h2>
                                <p>Defeito: {order.service}</p>
                                <div className="btndivWatch">
                                    <button className="btnWatch" onClick={() => handleAccept(order)}>Aceitar</button>
                                    <select
                                        name="select"
                                        id="select"
                                        onChange={(event) => handleSelectChange(order.id, event)}
                                    >
                                        <option>Quem Aceitou?</option>
                                        <option value="André">André</option>
                                        <option value="Leandro">Leandro</option>
                                        <option value="Cristian">Cristian</option>
                                        <option value="Erik">Erik</option>
                                    </select>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhum chamado disponível.</p>
                    )}
                </div>

                <div className="services-listp">
                    <div className="titleCardAmanha">
                        <h1>Amanhã</h1>
                    </div>
                    {categorizedOrders.Amanhã.length > 0 ? (
                        categorizedOrders.Amanhã.map((order, index) => (
                            <div key={index} className="service-itemAmanha">
                                <h2>Ordem: {order.numOrder}</h2>
                                <h2>Cliente: {order.client}</h2>
                                <p>Defeito: {order.service}</p>
                                <div className="btndivWatch">
                                    <button className="btnWatch" onClick={() => handleAccept(order)}>Aceitar</button>
                                    <select
                                        name="select"
                                        id="select"
                                        onChange={(event) => handleSelectChange(order.id, event)}
                                    >
                                        <option>Quem Aceitou?</option>
                                        <option value="André">André</option>
                                        <option value="Leandro">Leandro</option>
                                        <option value="Cristian">Cristian</option>
                                        <option value="Erik">Erik</option>
                                    </select>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhum chamado disponível.</p>
                    )}
                </div>

                <div className="services-listp">
                    <div className="titleCardAgendado">
                        <h1>Agendado</h1>
                    </div>
                    {categorizedOrders.Agendado.length > 0 ? (
                        categorizedOrders.Agendado.map((order, index) => (
                            <div key={index} className="service-itemAgendado">
                                <h2>Ordem: {order.numOrder}</h2>
                                <h2>Cliente: {order.client}</h2>
                                <p>Defeito: {order.service}</p>
                                <div className="divData">
                                    <p><strong>Data:</strong> <strong>{new Date(order.data).toLocaleDateString('pt-BR')}</strong></p>
                                </div>
                                <div className="btndivWatch">
                                    <button className="btnWatch" onClick={() => handleAccept(order)}>Aceitar</button>
                                    <select
                                        name="select"
                                        id="select"
                                        onChange={(event) => handleSelectChange(order.id, event)}
                                    >
                                        <option>Quem Aceitou?</option>
                                        <option value="André">André</option>
                                        <option value="Leandro">Leandro</option>
                                        <option value="Cristian">Cristian</option>
                                        <option value="Erik">Erik</option>
                                    </select>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhum chamado disponível.</p>
                    )}
                </div>
            </main>
        </main>
    );
};

export default WatchService;
